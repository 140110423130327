/** @jsx jsx */
import { jsx, Grid, Box } from 'theme-ui'
import React from 'react'
import Layout from '../../components/layout'
import { HeroLayout } from '../../components/hero'
import Wrapper from '../../components/layout/wrapper'
import Inner from '../../components/layout/inner'
import SectionHeader from '../../components/sectionHeader'
import Avatar from '../../components/avatar'
import { graphql } from 'gatsby'
import Promo from '../../components/promo'
import MetaData from '../../components/metadata'

const Tile = props => {
  return (
    <Avatar
      image={props.thumbnail?.file?.url}
      gatsbyImage={props.thumbnail?.gatsbyImageData}
      name={props.title}
      url={props.hasPage ? `/team/player/${props.slug}` : false}
    />
  )
}

const Teams = props => {
  const page = props.data?.contentfulTeam
  const columns = [
    1,
    null,
    null,
    'repeat(2, 1fr)',
    null,
    null,
    'repeat(4, 1fr)',
  ]

  return (
    <Layout {...props}>
      <MetaData title={page?.title} />
      <HeroLayout
        background={page?.image?.file?.url}
        gatsbyBgImg={page?.image?.gatsbyImageData}
        bar={page.title}
        variant={page?.variant === 'Gold' ? 'gold' : false}
      />

      <Wrapper>
        <Inner>
          {page?.groups.map((x, i) => {
            return (
              <React.Fragment key={i}>
                {x.displayTitle && (
                  <SectionHeader
                    title={x.displayTitle}
                    variant="underline"
                    sx={{ marginTop: 9 }}
                  />
                )}
                <Grid columns={columns} mb={4} mt={x.displayTitle ? 0 : 4}>
                  {x.players?.map((x, i) => {
                    return <Tile key={i} {...x} />
                  })}
                </Grid>
              </React.Fragment>
            )
          })}
        </Inner>
      </Wrapper>

      {page?.promo && (
        <Wrapper>
          <Inner>
            <Promo data={page?.promo} dark={props.light} />
          </Inner>
        </Wrapper>
      )}
    </Layout>
  )
}

export default Teams

export const pageQuery = graphql`
  query TeamBySlug($slug: String!) {
    contentfulTeam(slug: { eq: $slug }) {
      title
      variant
      image {
        file {
          url
        }
        gatsbyImageData(layout: FIXED, width: 1700, height: 580)
      }
      groups {
        displayTitle
        players {
          ...PlayerFragment
        }
      }
      promo {
        ...PromoFragment
      }
    }
  }
`
