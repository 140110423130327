/** @jsx jsx */
import { jsx, Text, Card } from 'theme-ui';
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

const Avatar = (props) => {

  const cardSX = {
    position: 'relative',
    height: '0px',
    pb: '100%',
    display: 'block',
  }

  const nameSX = {
    position: 'absolute',
    bottom: 2,
    left: 2,
    fontFamily: 'heading',
    fontSize: 9,
    color: 'white'
  }

  const imageSX = {
    position: 'absolute !important',
    top: '0px',
    left: '0px',
    width: '100% !important',
    height: '100% !important',
    objectFit: 'cover'
  }

  return (
    <Card sx={cardSX} variant="slim" as={props.url ? Link : 'figure'} to={props.url}>
      {(props.image && !props.gatsbyImage) &&
        <img sx={imageSX} src={props.image} alt={props.name} />
      }
      {props.gatsbyImage &&
        <GatsbyImage image={props.gatsbyImage} sx={imageSX} alt={props.name} />
      }
      <Text sx={nameSX}>{props.name}</Text>
    </Card>
  );
}

export default Avatar